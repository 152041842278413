<template>
  <div id="holidays" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >DÍAS DE VACIONES POR AÑOS</v-row
      >
      <v-container fluid class="addEnterprise">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol holidays">
            <v-row>
              <div class="form-group">
                <label for="calendar">Empresa</label>
                <select
                  name="empresa"
                  id="empresa"
                  v-model="enterprise"
                  @change="consultaVacaciones()"
                >
                  <option disabled value="0" selected>
                    Selecciona Empresa
                  </option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="a0">Año 0</label>
                <select name="a0" id="a0" v-model="a0">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a1">Año 1</label>
                <select name="a1" id="a1" v-model="a1">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a2">Año 2</label>
                <select name="a2" id="a0" v-model="a2">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a3">Año 3</label>
                <select name="a3" id="a3" v-model="a3">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a4">Año 4</label>
                <select name="a4" id="a4" v-model="a4">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a5">Año 5</label>
                <select name="a5" id="a5" v-model="a5">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a10">Año 10</label>
                <select name="a10" id="a10" v-model="a10">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a15">Año 15</label>
                <select name="a15" id="a15" v-model="a15">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a20">Año 20</label>
                <select name="a20" id="a20" v-model="a20">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a25">Año 25</label>
                <select name="a25" id="a25" v-model="a25">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a30">Año 30</label>
                <select name="a30" id="a20" v-model="a30">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
              <div class="form-group">
                <label for="a35">Año 35</label>
                <select name="a35" id="a35" v-model="a35">
                  <option selected disabled value="0">Seleccione</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.name }}
                  </option></select
                >días
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <button class="botonAmarilloDerecho" @click="cancelar()">
                Regresar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="530">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      message: "",
      //dialogs de empleado
      errors: [],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      nuevoRegistro: true,
      id: 0,
      days: [],
      a0: 0,
      a1: 0,
      a2: 0,
      a3: 0,
      a4: 0,
      a5: 0,
      a10: 0,
      a15: 0,
      a20: 0,
      a25: 0,
      a30: 0,
      a35: 0,
    };
  },
  methods: {
    validacion() {
      this.errors = [];
      this.message = "Días de vaciones";
      if (this.enterprise == 0 || this.enterprise == "") {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.a0 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 0 años."
        );
      }
      if (this.a1 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 1 años."
        );
      }
      if (this.a2 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 2 años."
        );
      }
      if (this.a3 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 3 años."
        );
      }
      if (this.a4 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 4 años."
        );
      }
      if (this.a5 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 5 años."
        );
      }
      if (this.a10 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 10 años."
        );
      }
      if (this.a15 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 15 años."
        );
      }
      if (this.a20 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 20 años."
        );
      }
      if (this.a25 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 25 años."
        );
      }
      if (this.a30 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 30 años."
        );
      }
      if (this.a35 == 0) {
        this.errors.push(
          "Se debe especificar el número de días para la antigüedad de 35 años."
        );
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la almecenar los días de vacaciones.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    /* aux() {
      this.$router.go(0);
    }, */
    cancelar() {
      this.a0 = 0;
      this.a1 = 0;
      this.a2 = 0;
      this.a3 = 0;
      this.a4 = 0;
      this.a5 = 0;
      this.a6 = 0;
      this.a7 = 0;
      this.a8 = 0;
      this.a9 = 0;
      this.a10 = 0;
      this.a15 = 0;
      this.a20 = 0;
      this.a25 = 0;
      this.a30 = 0;
      this.a35 = 0;
      this.$router.push("/dashboard");
    },
    guardar() {
      this.advice = false;
      if (this.nuevoRegistro) {
        this.show = true;
        axios
          .post(
            Server + "/vacacionesAnio",
            {
              AnioCero: this.a0,
              AnioUno: this.a1,
              AnioDos: this.a2,
              AnioTres: this.a3,
              AnioCuatro: this.a4,
              AnioCinco: this.a5,
              AnioDiez: this.a10,
              AnioQuince: this.a15,
              AnioVeinte: this.a20,
              AnioVeinticinco: this.a25,
              AnioTreinta: this.a30,
              AnioTreintaicinco: this.a35,
              EmpresaId: this.enterprise,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            this.a0 = 0;
            this.a1 = 0;
            this.a2 = 0;
            this.a3 = 0;
            this.a4 = 0;
            this.a5 = 0;
            this.a10 = 0;
            this.a15 = 0;
            this.a20 = 0;
            this.a25 = 0;
            this.a30 = 0;
            this.a35 = 0;
            this.nuevoRegistro = true;
            this.enterprise = 0;
            this.message = "Confirmación";
            this.respuesta = "Los días fueron almacenados con éxito";
            this.show = false;
            this.confirmation = true;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            } else {
              this.a0 = 0;
              this.a1 = 0;
              this.a2 = 0;
              this.a3 = 0;
              this.a4 = 0;
              this.a5 = 0;
              this.a10 = 0;
              this.a15 = 0;
              this.a20 = 0;
              this.a25 = 0;
              this.a30 = 0;
              this.a35 = 0;
              this.enterprise = 0;
              this.message = "AVISO";
              this.nuevoRegistro = true;
              this.respuesta = "Los días no fueron almacenados con éxito";
              this.confirmation = true;
            }
          });
      } else {
        this.show = true;
        axios
          .put(
            Server + "/vacacionesAnio/" + this.id,
            {
              Id: this.id,
              AnioCero: this.a0,
              AnioUno: this.a1,
              AnioDos: this.a2,
              AnioTres: this.a3,
              AnioCuatro: this.a4,
              AnioCinco: this.a5,
              AnioDiez: this.a10,
              AnioQuince: this.a15,
              AnioVeinte: this.a20,
              AnioVeinticinco: this.a25,
              AnioTreinta: this.a30,
              AnioTreintaicinco: this.a35,
              EmpresaId: this.enterprise,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            this.a0 = 0;
            this.a1 = 0;
            this.a2 = 0;
            this.a3 = 0;
            this.a4 = 0;
            this.a5 = 0;
            this.a10 = 0;
            this.a15 = 0;
            this.a20 = 0;
            this.a25 = 0;
            this.a30 = 0;
            this.a35 = 0;
            this.enterprise = 0;
            this.nuevoRegistro = true;
            this.message = "Confirmación";
            this.respuesta = "Los días fueron actualizados con éxito";
            this.show = false;
            this.confirmation = true;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            } else {
              this.a0 = 0;
              this.a1 = 0;
              this.a2 = 0;
              this.a3 = 0;
              this.a4 = 0;
              this.a5 = 0;
              this.a10 = 0;
              this.a15 = 0;
              this.a20 = 0;
              this.a25 = 0;
              this.a30 = 0;
              this.a35 = 0;
              this.message = "AVISO";
              this.nuevoRegistro = true;
              this.enterprise = 0;
              this.respuesta = "Los días no fueron actualizados con éxito";
              this.confirmation = true;
            }
          });
      }
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          // console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    consultaVacaciones() {
      this.a0 = 0;
      this.a1 = 0;
      this.a2 = 0;
      this.a3 = 0;
      this.a4 = 0;
      this.a5 = 0;
      this.a10 = 0;
      this.a15 = 0;
      this.a20 = 0;
      this.a25 = 0;
      this.a30 = 0;
      this.a35 = 0;
      this.show = true;
      axios
        .get(Server + "/vacacionesAnio/empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var aux = response.data;
          if (aux.length > 0) {
            this.nuevoRegistro = false;
            (this.id = aux[0].id), (this.a0 = aux[0].anioCero);
            this.a1 = aux[0].anioUno;
            this.a2 = aux[0].anioDos;
            this.a3 = aux[0].anioTres;
            this.a4 = aux[0].anioCuatro;
            this.a5 = aux[0].anioCinco;
            this.a10 = aux[0].anioDiez;
            this.a15 = aux[0].anioQuince;
            this.a20 = aux[0].anioVeinte;
            this.a25 = aux[0].anioVeinticinco;
            this.a30 = aux[0].anioTreinta;
            this.a35 = aux[0].anioTreintaicinco;
          } else {
            this.nuevoRegistro = true;
          }
          //console.log("Registro estatus",this.nuevoRegistro);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    for (let index = 1; index <= 60; index++) {
      this.days.push({
        id: index,
        name: index.toString(),
      });
    }
    this.listar();
    this.consultaVacaciones();
  },
};
</script>